import { Button, HStack, Icon, Text } from '@chakra-ui/react'
import { useActions } from 'kea'
import { CiMoneyCheck1 } from 'react-icons/ci'

import PlanTierTag from './PlanTierTag'
import { planManagementLogic } from './planManagementLogic'

export default function OpenSaasPlanManagementButton(): JSX.Element | null {
  const { openModal } = useActions(planManagementLogic)

  return (
    <Button variant="unstyled" onClick={openModal} fontWeight="normal">
      <HStack mt="2" borderLeftRadius="lg" w="full" gap={2}>
        <Icon as={CiMoneyCheck1}></Icon>
        <Text>My Plan</Text>
        <PlanTierTag />
      </HStack>
    </Button>
  )
}
