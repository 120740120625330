import { extendTheme } from '@chakra-ui/react'
import '@fontsource-variable/rubik'

// example theme
export const theme = extendTheme({
  colors: {
    border: '#CBD5E0',
    brandGreen: {
      '50': '#F5F4F0',
      '100': '#E3E1D4',
      '200': '#D0CEB8',
      '300': '#BEBA9D',
      '400': '#ACA781',
      '500': '#8B865C', // the actual color. 500 is the base of a full color scheme.
      '600': '#7B7651',
      '700': '#5C593D',
      '800': '#3D3B29',
      '900': '#1F1E14',
    },
    brandTan: '#f9f4e8',
    brandBrown: {
      50: '#fdf5e8',
      100: '#e9ddc8',
      200: '#d9c4a5',
      300: '#caaa82',
      400: '#bb8f5e',
      500: '#a27245',
      600: '#7e5635',
      700: '#5b3b26',
      800: '#372216',
      900: '#160b01',
    },
    brandBrownLight: '#72482d',
    brandOrange: {
      50: 'rgba(251, 247, 244, 1)',
      100: '#f8e8dd',
      200: '#f4dac8',
      300: '#efcbb3',
      400: '#ebbd9e',
      500: '#e2a074', // the actual color. 500 is the base of a full color scheme.
      600: '#d9834a',
      700: '#d57535',
      800: '#c76829',
      900: '#9c5221',
    },
    inputBorderColor: '#CBD5E0',
  },
  fonts: {
    heading: `'Rubik Variable', sans-serif`,
    body: `'Rubik Variable', sans-serif`,
  },
  fontSizes: {},
  fontWeights: {
    // dropped all the defaults down by 100
    hairline: 100,
    thin: 100,
    light: 200,
    normal: 300,
    medium: 400,
    semibold: 500,
    bold: 600,
    extrabold: 700,
    black: 800,
  },
  lineHeights: {},
  letterSpacings: {},
  borders: {
    border: `1px solid var(--chakra-colors-gray-200)`,
  },
  layerStyles: {
    borderedSection: {
      border: 'border',
      borderRadius: 'lg',
      p: 5,
    },
    tightBorderedSection: {
      border: 'border',
      borderRadius: 'md',
      p: 3,
      h: 'min-content',
    },
  },
  textStyles: {
    disclosure: {
      fontSize: 'sm',
      color: 'gray.500',
    },
    'faint-italic': {
      color: 'gray.400',
      fontStyle: 'italic',
    },
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'var(--chakra-colors-inputBorderColor)',
          },
        },
      },
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            borderColor: 'var(--chakra-colors-inputBorderColor)',
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderColor: 'var(--chakra-colors-inputBorderColor)',
          },
        },
      },
    },
  },
})
