import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useActions, useValues } from 'kea'
import { inflect, toPercentString } from 'lib/Utils'
import { userLogic } from 'lib/logics/userLogic'
import { FaTimesCircle } from 'react-icons/fa'
import { MdCheckCircle } from 'react-icons/md'
import { billingRedirectLogic } from 'scenes/billing-redirects/billingRedirectLogic'
import Money from 'scenes/buy-sensors/Money'

import { ReactNode } from 'react'

import { PURCHASE_OFFERINGS, planManagementLogic } from './planManagementLogic'

export default function ChoosePlan(): JSX.Element {
  const { getCheckoutLink } = useActions(planManagementLogic)
  const { organizationIsSubscriber, organizationPlanTier, organizationBillingProvider } = useValues(userLogic)

  return (
    <VStack px={10} gap={10}>
      <HStack w="full" spacing={5} justify={'space-between'}>
        <SensorsOnlyPlan />
        {PURCHASE_OFFERINGS.map((offering) => {
          const isCurrentPlan = organizationPlanTier?.toLowerCase() === offering.slug
          return (
            <Card
              minH="md"
              bg="orange.50"
              borderRadius="2xl"
              key={offering.slug}
              border={isCurrentPlan ? '1px solid black' : ''}
            >
              <CardHeader pb={3}>
                <Heading size={'xl'} textAlign={'center'}>
                  {offering.name}
                </Heading>
              </CardHeader>
              <CardBody>
                <Stack>
                  <Heading size={'lg'}>
                    <Money cents={false}>{offering.price}</Money> / mo.
                  </Heading>
                  <Box borderTop={'1px solid'} borderBottom={'1px solid'} borderColor={'gray.300'} py={3} fontSize="lg">
                    <Text fontWeight="bold">Shipment Recommendations</Text>
                    <Box>
                      <Text>{offering.features.shipmentMonthlyLimit} / mo.</Text>
                      <Text color="gray.600" fontSize="sm">
                        (<Money cents>{offering.features.shipmentOveragePrice}</Money> each after that)
                      </Text>
                    </Box>
                  </Box>
                  <List>
                    <FeatureLineItem available>View sensor data</FeatureLineItem>
                    <FeatureLineItem available>
                      {offering.features.distCenterLimit}{' '}
                      {inflect(offering.features.distCenterLimit, 'distribution center')}
                    </FeatureLineItem>
                    <FeatureLineItem available>
                      {offering.features.packagingModelLimit}{' '}
                      {inflect(offering.features.packagingModelLimit, 'packaging type')}
                    </FeatureLineItem>
                    <FeatureLineItem available>{offering.features.support} support</FeatureLineItem>
                    <FeatureLineItem available>Simulation Lab</FeatureLineItem>
                    <FeatureLineItem available>
                      {toPercentString(offering.features.sensorDiscount)} sensor discount
                    </FeatureLineItem>
                    <FeatureLineItem available={offering.features.apiAccess}>Api access</FeatureLineItem>
                  </List>
                </Stack>
              </CardBody>
              <CardFooter justifyContent={'center'}>
                <VStack>
                  <Text fontStyle={'italic'} fontSize="sm" fontWeight="bold">
                    {organizationIsSubscriber ? '​' : '14 day free trial'}
                  </Text>
                  {isCurrentPlan ? (
                    <Text fontStyle={'italic'} fontWeight={'bold'} fontSize={'lg'} textColor="brandGreen">
                      Your current plan
                    </Text>
                  ) : organizationIsSubscriber && organizationBillingProvider === 'STRIPE' ? (
                    // stripe users still need to contact us to change their plan
                    <Button>
                      <Link href="mailto:support@keepit.cool?subject=Plan change request">Contact us to upgrade</Link>
                    </Button>
                  ) : (
                    <Button onClick={() => getCheckoutLink(offering)}>
                      {organizationIsSubscriber ? 'Change your plan' : 'Get Started'}
                    </Button>
                  )}
                </VStack>
              </CardFooter>
            </Card>
          )
        })}
      </HStack>
      {organizationBillingProvider === 'STRIPE' && (
        <Stack justify={'center'} align={'center'} spacing={3}>
          <NeedMore />
          {organizationIsSubscriber && (
            <Link
              size="xs"
              _hover={{ color: 'red.700' }}
              href="mailto:support@keepit.cool?subject=Cancel plan"
              color="gray.800"
            >
              Need to cancel?
            </Link>
          )}
        </Stack>
      )}
    </VStack>
  )
}

function FeatureLineItem({ available, children }: { children: ReactNode; available: boolean }): JSX.Element {
  return (
    <ListItem>
      <ListIcon as={available ? MdCheckCircle : FaTimesCircle} color={available ? 'brandGreen' : 'red.700'} mr={3} />
      {children}
    </ListItem>
  )
}

// don't show this if you have a plan
function SensorsOnlyPlan(): JSX.Element | null {
  const { loadFinalizeSaasPlanSelection } = useActions(billingRedirectLogic)
  const { organizationPlanTier, organizationBillingProvider } = useValues(userLogic)

  const isCurrentPlan = organizationPlanTier === 'SENSORS_ONLY'
  if (
    (organizationPlanTier && !['NO_PLAN', 'SENSORS_ONLY'].includes(organizationPlanTier)) ||
    organizationBillingProvider === 'SHOPIFY'
  ) {
    return null
  }

  return (
    <Card minH="md" bg="orange.50" borderRadius="2xl" border={isCurrentPlan ? '1px solid black' : ''}>
      <CardHeader pb={3}>
        <Heading size={'xl'} textAlign={'center'}>
          Free
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack pb={5}>
          <Heading size={'lg'}>$0 / mo.</Heading>
          <Box borderTop={'1px solid'} borderBottom={'1px solid'} borderColor={'gray.300'} py={3} fontSize="lg">
            <Text fontWeight="bold">Shipment Recommendations</Text>
            <Text color="gray.600">10 / week</Text>
            <Text fontSize="sm" color="gray.600">
              (Upgrade for more)
            </Text>
          </Box>
          <List>
            <FeatureLineItem available>View sensor data</FeatureLineItem>
            <FeatureLineItem available>1 Distribution Center</FeatureLineItem>
            <FeatureLineItem available>2 Packaging Models</FeatureLineItem>
            <FeatureLineItem available>Self service support</FeatureLineItem>
            <FeatureLineItem available={false}>Simulation Lab</FeatureLineItem>
            <FeatureLineItem available={false}>Sensor Discount</FeatureLineItem>
            <FeatureLineItem available={false}>Api access</FeatureLineItem>
          </List>
        </Stack>
      </CardBody>
      <CardFooter justifyContent={'center'}>
        <VStack>
          <Text fontStyle={'italic'} fontSize="sm" fontWeight="bold">
            ​
          </Text>
          {isCurrentPlan ? (
            <Text fontStyle={'italic'} fontWeight={'bold'} fontSize={'lg'} textColor="brandGreen">
              Your current plan
            </Text>
          ) : (
            <Button onClick={() => loadFinalizeSaasPlanSelection({ plan: 'SENSORS_ONLY' })}>
              {isCurrentPlan ? 'Change your plan' : 'Get Started'}
            </Button>
          )}
        </VStack>
      </CardFooter>
    </Card>
  )
}

function NeedMore(): JSX.Element {
  return (
    <Card bg="orange.50" borderRadius="2xl" maxH="10rem">
      <CardHeader pb="0">
        <Heading textAlign={'center'} size={'lg'}>
          Need More?
        </Heading>
      </CardHeader>
      <CardFooter justifyContent={'center'}>
        <Button>
          <Link href="https://www.keepit.cool/book-a-demo">
            Reach out to talk about Enterprise Pricing.
            <ExternalLinkIcon ml={2} />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  )
}
