import { actions, kea, path, reducers } from 'kea'

import type { sideBarLogicType } from './sideBarLogicType'

export const sideBarLogic = kea<sideBarLogicType>([
  path(['lib', 'components', 'SideBar', 'sideBarLogic']),
  reducers({
    sideBarIsOpen: [
      true,
      { persist: true },
      {
        toggleSideBar: (sideBarIsOpen) => !sideBarIsOpen,
      },
    ],
  }),
  actions({ toggleSideBar: true }),
])
