import { actions, events, kea, path, selectors } from 'kea'
import { loaders } from 'kea-loaders'
import { api } from 'lib/Api'
import { IntegrationIngestionFilter, OMSProvider, RecommendationPolicy, RecommendationTriggerPolicy } from 'lib/Types'
import { DataMapping } from 'lib/components/forms/DataMapping'

import type { integrationsLogicType } from './integrationsLogicType'

export interface ServerSideIntegration {
  provider: OMSProvider
  uuid?: string
  coolant_field?: string
  ingestion_filters?: IntegrationIngestionFilter
  expected_ship_date_field?: string
  ship_date_recommendation_policy?: RecommendationPolicy
  default_recommendation_trigger_policy?: RecommendationTriggerPolicy
  coolant_value_mappings?: DataMapping[]
  kic_dc_to_external_dc_mapping?: DataMapping[]
  external_to_kic_shipping_service_level_mapping?: DataMapping[]
  is_sync_active?: boolean
  shopify_shop_url?: string
  shipping_method_recommendation_policy?: RecommendationPolicy
  packaging_recommendation_policy?: RecommendationPolicy
  distribution_center_recommendation_policy?: RecommendationPolicy
  kic_to_external_shipping_method_mapping?: DataMapping[]
  kic_to_external_packaging_mapping?: DataMapping[]
  warnings_data_mapping?: DataMapping[]
  distribution_center_field?: string
  shipping_method_field?: string
  packaging_config_field?: string
  recommendation_made_tag_id?: string
  logistics_plan_field?: string
  should_update_shipment_weight_to_include_coolant?: boolean
}

export enum IntegrationAuthenticationField {
  ApiKey = 'api_key',
  ApiSecret = 'api_secret',
}

export interface IntegrationAuthenticationFieldDefinition {
  name: IntegrationAuthenticationField
  label: string
  type: 'text' | 'password'
}

export const IntegrationAuthenticationFields: Record<
  IntegrationAuthenticationField,
  IntegrationAuthenticationFieldDefinition
> = {
  [IntegrationAuthenticationField.ApiKey]: {
    name: IntegrationAuthenticationField.ApiKey,
    label: 'API Key',
    type: 'password',
  },
  [IntegrationAuthenticationField.ApiSecret]: {
    name: IntegrationAuthenticationField.ApiSecret,
    label: 'API Secret',
    type: 'password',
  },
}

export interface RecommendationFieldOption {
  value: string
  label: string
}

export interface Integration extends ServerSideIntegration {
  provider: OMSProvider
  name: string
  logo: string
  url: string
  urlText?: string
  description: string
  integrationDescription: string
  authenticationInstructions: string
  detailedAuthenticationInstructions: string
  authenticationFields?: IntegrationAuthenticationField[]
  coolantRecommendationFieldOptions?: RecommendationFieldOption[]
  distributionCenterFieldOptions?: RecommendationFieldOption[]
  shippingMethodFieldOptions?: RecommendationFieldOption[]
  packagingFieldOptions?: RecommendationFieldOption[]
  expectedShipDateFieldOptions?: RecommendationFieldOption[]
  make_generalized_recommendation?: boolean
}

export const allIntegrations: Record<OMSProvider, Integration> = {
  [OMSProvider.ShipStation]: {
    provider: OMSProvider.ShipStation,
    name: 'ShipStation',
    description:
      'ShipStation is a web-based shipping solution that helps you get orders out the door quickly and easily.',
    integrationDescription:
      'This integration allows you to automatically sync shipments between Keep it Cool and ShipStation.',
    authenticationInstructions: 'Please enter your ShipStation API key and secret.',
    detailedAuthenticationInstructions: `
1. Log in to your ShipStation account.
2. Go to Settings > Account > API Settings.
3. Scroll down to "API Keys"
![ShipStation API Key](/ship-station-api-key-flow-2.png)
4. If you don't have an API key generated, click "Generate API Keys"
![ShipStation API Key](/ship-station-api-key-flow-1.png)
5. Copy the API Key and API Secret into the integration form in Keep it Cool.
6. Click "Save" to complete the integration.
  `,
    logo: '/ship-station-logo.png',
    url: 'https://www.shipstation.com/',
    authenticationFields: [IntegrationAuthenticationField.ApiKey, IntegrationAuthenticationField.ApiSecret],
    coolantRecommendationFieldOptions: [
      { value: 'advancedOptions.customField1', label: 'Custom Field 1' },
      { value: 'advancedOptions.customField2', label: 'Custom Field 2' },
      { value: 'advancedOptions.customField3', label: 'Custom Field 3' },
    ],
    distributionCenterFieldOptions: [{ value: 'SHIP_STATION_ORDER_WAREHOUSE', label: 'ShipStation From Location' }],
    shippingMethodFieldOptions: [{ value: 'SHIP_STATION_CARRIER_CODE', label: 'ShipStation Carrier Service' }],
    packagingFieldOptions: [{ value: 'SHIP_STATION_PACKAGE_DIMENSIONS', label: 'ShipStation Package Dimensions' }],
    expectedShipDateFieldOptions: [{ value: 'shipByDate', label: 'Ship Station Ship By Date' }],
  },
  [OMSProvider.Shopify]: {
    provider: OMSProvider.Shopify,
    name: 'Shopify',
    description: 'Shopify is a leading e-commerce platform that allows you to create and manage online stores.',
    integrationDescription: `
This integration allows you to automatically sync orders between Keep it Cool and Shopify.
Your orders from Shopify will be imported into Keep it Cool.
At the same time, Keep It Cool will add data to back to your Shopify to recommend optimal shipment plans.
`,
    authenticationInstructions: 'Please install the Keep It Cool Shopify app in the Shopify App Store.',
    detailedAuthenticationInstructions: `
1. Follow the link below to the Shopify App Store and click 'Install'.
2. Follow the instructions to approve and install the Keep It Cool app.
3. Once you've authorized Keep It Cool, you'll be redirected back to Keep it Cool.
`,
    logo: '/shopify-logo.png',
    url: 'https://apps.shopify.com/keep-it-cool',
    urlText: 'Install in the shopify store',
  },
}

export const integrationsLogic = kea<integrationsLogicType>([
  path(['scenes', 'integrations', 'integrationsLogic']),
  actions({
    loadIntegrations: true,
    setIntegrationValues: (provider: OMSProvider, integrationValues: ServerSideIntegration) => ({
      provider,
      integrationValues,
    }),
  }),
  loaders(({ values }) => ({
    integrations: [
      allIntegrations as Record<OMSProvider, Integration>,
      {
        setIntegrationValues: ({ provider, integrationValues }) => {
          return {
            ...values.integrations,
            [provider]: {
              ...values.integrations[provider],
              ...integrationValues,
            },
          }
        },
        loadIntegrations: async (_, breakpoint) => {
          breakpoint()
          const url = '/api/oms-integrations/'
          const response: ServerSideIntegration[] = await api.get(url)
          const integrationsToReturn = { ...allIntegrations }
          for (const integration of response) {
            integrationsToReturn[integration.provider] = {
              ...integration,
              ...integrationsToReturn[integration.provider],
            }
          }
          return integrationsToReturn
        },
      },
    ],
  })),
  selectors({
    organizationHasIntegration: [
      (selectors) => [selectors.integrationsLoading, selectors.integrations],
      (integrationsLoading, integrations) => (provider: OMSProvider) =>
        !integrationsLoading && !!Object.values(integrations).find((i) => i.provider === provider)?.uuid,
    ],
  }),
  events(({ actions }) => ({
    afterMount: () => {
      actions.loadIntegrations()
    },
  })),
])
